import React from 'react';

interface ProductsProps {
  path: string;
  children: React.ReactChildren;
}

const Products = (props: ProductsProps) => {
  return <>{props.children}</>;
};

export default Products;
