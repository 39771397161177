import { Form, Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import { formatAsCurrency } from '../../util';
import {
  formatPaymentMethod,
  formatPaymentStatus,
  paymentMethodOptions,
  ProductPurchaseProps
} from '../../util/products';
import Button from '../common/Button';
import Card from '../common/Card';
import { FormInput } from '../common/FormInput';
import SEO from '../Seo';
import {
  deleteProductPurchase,
  getAffiliatedOrganizations,
  getAffiliatedUsers,
  getCashnetPaymentLink,
  getProductPurchase,
  updateProductPurchase,
  updateSeatAssignments
} from './api';
import InfoBlock from './InfoBlock';
import InfoBlockEdit from './InfoBlockEdit';
import { IProductPurchase, ISeatAssignment } from './NewApiTypes';
import UserSelect from './UserSelect';
import { toast } from 'react-toastify';

const productTotalHasDiscount = (productPurchase: IProductPurchase) => {
  const targetPrice = productPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return false;
  }
  const discountSeatThreshold = targetPrice.discountSeatThreshold;
  const discountAfterThresholdPercent =
    targetPrice.discountAfterThresholdPercent;
  const totalSeats = productPurchase.seatAssignments.length;
  return (
    totalSeats >= discountSeatThreshold && discountAfterThresholdPercent > 0
  );
};

const getProductPriceBeforeDiscount = (productPurchase: IProductPurchase) => {
  const targetPrice = productPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  return (targetPrice.price / 100) * productPurchase.seatAssignments.length;
};

const getDiscountAfterThresholdPercent = (
  productPurchase: IProductPurchase
) => {
  const targetPrice = productPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  return targetPrice.discountAfterThresholdPercent;
};

const getProductPurchaseTotalCost = (productPurchase: IProductPurchase) => {
  const targetPrice = productPurchase.price;
  if (!targetPrice || !targetPrice.price) {
    return null;
  }
  const discountSeatThreshold = targetPrice.discountSeatThreshold;
  const discountAfterThresholdPercent =
    targetPrice.discountAfterThresholdPercent;
  const totalSeats = productPurchase.seatAssignments.length;
  const totalPriceBeforeDiscount = (targetPrice.price / 100) * totalSeats;
  if (
    totalSeats >= discountSeatThreshold &&
    discountAfterThresholdPercent > 0
  ) {
    return totalPriceBeforeDiscount * (1 - discountAfterThresholdPercent / 100);
  }
  return totalPriceBeforeDiscount;
};

const getDefaultAssignedUser = (
  assignment: ISeatAssignment,
  affiliatedUsers: any[]
) => {
  const user = affiliatedUsers.find(user => user.id === assignment.user?.id);
  if (!user) {
    return null;
  }

  return {
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
    email: user.email
  };
};

const getAvailableUsersToAssign = (
  seatAssignments: any[],
  affiliatedUsers: any[]
) => {
  const availableUsers = affiliatedUsers.filter(
    user => !seatAssignments.some(sa => sa.userId === user.id)
  );

  return availableUsers.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.id,
    email: user.email
  }));
};

const getDefaultOrganization = (
  organizations: any[],
  currentOrganizationId: string
) => {
  const matchedOrg = organizations.find(
    org => org.organization.id === currentOrganizationId
  );
  if (matchedOrg) {
    return {
      label: matchedOrg.organization.name,
      value: matchedOrg.organization.id
    };
  }
  return null;
};

const ProductPurchase = (props: ProductPurchaseProps) => {
  const { isFetching, error, data, refetch } = useQuery(
    'productPurchase',
    () => getProductPurchase(props.productPurchaseId),
    {
      onSuccess: data => {
        if (data.productPurchase) {
          setSeatAssignments(
            data.productPurchase.seatAssignments.map((sa: any) => ({
              seatAssignmentId: sa.id,
              userId: sa.user?.id ?? null
            }))
          );
        }
      }
    }
  );
  const { data: affiliatedUsers } = useQuery('affiliatedUsers', () =>
    getAffiliatedUsers()
  );

  const { data: organizations } = useQuery(
    'organizations',
    getAffiliatedOrganizations
  );

  const { mutate: deletePurchase } = useMutation(
    async (productPurchaseId: string) => {
      return await deleteProductPurchase(productPurchaseId);
    },
    {
      onSuccess: () => {
        navigate('/members/product-purchases/my-products');
      },
      onError: () => {
        toast.error('There was an error deleting your purchase');
      }
    }
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isUpdatingSeats, setIsUpdatingSeats] = useState(false);
  const [seatAssignments, setSeatAssignments] = useState([]);

  return (
    <>
      <SEO title="My Product Purchase" />
      {isFetching && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {data && data.status === 'NOT_FOUND' && (
        <Card>
          <div>{data.error}</div>
        </Card>
      )}
      {data && data.productPurchase && (
        <>
          <h3 className="text-primary font-bold text-2xl mb-6">
            Manage Your Product Purchase
          </h3>
          <Card>
            <>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <h2 className="text-primary font-bold text-xl">
                    {data.productPurchase.product.name}
                  </h2>
                  <section className="mb-4">
                    <div
                      className="text-gray-600 w-full sm:w-2/3"
                      dangerouslySetInnerHTML={{
                        __html: data.productPurchase.product.description
                      }}
                    />
                  </section>
                  <Formik
                    initialValues={{
                      organizationId: data.productPurchase?.organization?.id,
                      paymentMethod: data.productPurchase.paymentMethod,
                      seats: data.productPurchase.seatAssignments.length
                    }}
                    onSubmit={async values => {
                      try {
                        await updateProductPurchase(
                          data.productPurchase.id,
                          values
                        );
                        setIsEdit(false);
                        refetch();
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  >
                    {({ errors, touched, setFieldValue, handleChange }) => (
                      <Form>
                        <section className="grid grid-cols-1 sm:grid-cols-6 gap-3">
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Organization">
                                <Select
                                  defaultValue={getDefaultOrganization(
                                    organizations.organizationMemberships,
                                    data.productPurchase.organization?.id
                                  )}
                                  options={organizations.organizationMemberships.map(
                                    (org: any) => ({
                                      label: org.organization.name,
                                      value: org.organization.id
                                    })
                                  )}
                                  onChange={value => {
                                    if (value) {
                                      setFieldValue(
                                        'organizationId',
                                        value.value
                                      );
                                    }
                                  }}
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Organization"
                                info={
                                  data.productPurchase.organization
                                    ? [data.productPurchase.organization.name]
                                    : []
                                }
                              />
                            )}
                          </div>
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Seats">
                                <FormInput
                                  size="md"
                                  ariaLabel="Seats"
                                  placeholder="Seats"
                                  type="number"
                                  name="seats"
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Seats"
                                info={[
                                  data.productPurchase.seatAssignments.length
                                ]}
                              />
                            )}
                          </div>
                          <div>
                            {isEdit ? (
                              <InfoBlockEdit title="Payment Method">
                                <Select
                                  options={paymentMethodOptions}
                                  defaultValue={paymentMethodOptions.find(
                                    option =>
                                      option.value ===
                                      data.productPurchase.paymentMethod
                                  )}
                                  onChange={value => {
                                    if (value) {
                                      setFieldValue(
                                        'paymentMethod',
                                        value.value
                                      );
                                    }
                                  }}
                                />
                              </InfoBlockEdit>
                            ) : (
                              <InfoBlock
                                title="Payment Method"
                                info={[
                                  formatPaymentMethod(
                                    data.productPurchase.paymentMethod
                                  )
                                ]}
                              />
                            )}
                          </div>
                          <div>
                            <InfoBlock
                              title="Payment Status"
                              info={[
                                formatPaymentStatus(
                                  data.productPurchase.paymentStatus
                                )
                              ]}
                            />
                          </div>
                        </section>

                        {data.productPurchase.paymentStatus !== 'COMPLETED' && (
                          <div className="flex w-full mt-4">
                            {isEdit ? (
                              <div className="mr-2">
                                <Button
                                  color="success"
                                  type="submit"
                                  text="Save Purchase Details"
                                  size="sm"
                                />
                              </div>
                            ) : (
                              <>
                                <div className="mr-2 mt-4">
                                  <Button
                                    type="button"
                                    text="Edit Purchase Details"
                                    size="sm"
                                    onClick={e => {
                                      e.preventDefault();
                                      setIsEdit(true);
                                    }}
                                  />
                                </div>
                                <div className="mr-2 mt-4">
                                  <Button
                                    type="button"
                                    color="error"
                                    text="Delete Purchase"
                                    size="sm"
                                    onClick={async e => {
                                      e.preventDefault();
                                      if (
                                        confirm(
                                          'Are you sure you want to delete this purchase?'
                                        )
                                      ) {
                                        await deletePurchase(
                                          data.productPurchase.id
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>

                  <section className="mt-8">
                    <h3 className="text-primary font-bold mb-4 text-lg">
                      Payment
                    </h3>
                    <div>
                      <div className="mb-2">
                        {getProductPurchaseTotalCost(data.productPurchase) ===
                        null ? (
                          <p className="text-red-500 italic">
                            Ticket pricing error. Please contact support.
                          </p>
                        ) : (
                          <section className="grid grid-cols-6 gap-3 mb-4">
                            {productTotalHasDiscount(data.productPurchase) && (
                              <>
                                <div>
                                  <InfoBlock
                                    infoClassNames={['line-through']}
                                    title="Original Price"
                                    info={[
                                      `${formatAsCurrency(
                                        getProductPriceBeforeDiscount(
                                          data.productPurchase
                                        ) ?? 0
                                      )}`
                                    ]}
                                  />
                                </div>
                                <div>
                                  <InfoBlock
                                    title="Volume Discount"
                                    info={[
                                      `${getDiscountAfterThresholdPercent(
                                        data.productPurchase
                                      )}%`
                                    ]}
                                  />
                                </div>
                              </>
                            )}
                            <div>
                              <InfoBlock
                                title="Total Price"
                                info={[
                                  `${formatAsCurrency(
                                    getProductPurchaseTotalCost(
                                      data.productPurchase
                                    ) ?? 0
                                  )}`
                                ]}
                              />
                            </div>
                          </section>
                        )}
                      </div>
                      {data.productPurchase.paymentMethod ===
                      'PURCHASE_ORDER' ? (
                        <>
                          {data.productPurchase.paymentStatus !==
                          'COMPLETED' ? (
                            <div className="w-1/2 px-3 py-2 bg-yellow-100 border border-yellow-300 rounded-md">
                              <p className="text-gray-800 font-bold text-lg sans-serif mb-1">
                                How to Submit Your Purchase Order
                              </p>
                              <p className="text-gray-700 sans-serif mb-2">
                                Please email your purchase order (in PDF format)
                                to{' '}
                                <a
                                  href="mailto:al-finance@tc.columbia.edu"
                                  className="underline text-blue-500 whitespace-nowrap"
                                >
                                  al-finance@tc.columbia.edu
                                </a>
                                .
                              </p>
                              <p className="text-gray-700 sans-serif mb-2">
                                Please include purchase order number and
                                organization name in the email subject line, and
                                list the name of the event and persons covered
                                in the email body. Purchase orders can be made
                                out to:
                              </p>
                              <p className="text-gray-700 sans-serif mb-2 font-semibold">
                                Advancing Literacy
                                <br />
                                Teachers College
                                <br />
                                525 West 120th Street, #77
                                <br />
                                New York, NY 10027
                              </p>
                              <p className="text-gray-700 sans-serif">
                                Once your PO is received we'll finalize your
                                acceptance and send the meeting details.
                              </p>
                            </div>
                          ) : (
                            <div className="w-1/2 px-3 py-2 bg-green-100 border border-green-300 rounded-md">
                              <p className="text-gray-800 font-bold text-lg sans-serif mb-1">
                                Purchase Order Received
                              </p>
                              <p className="text-gray-700 sans-serif">
                                Your purchase order has been received and
                                processed.
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {data.productPurchase.paymentStatus !==
                            'COMPLETED' && (
                            <>
                              <Button
                                type="button"
                                text="Pay Now"
                                size="sm"
                                disabled={!data.productPurchase.organization}
                                onClick={async () => {
                                  const {
                                    cashNetPaymentLink
                                  } = await getCashnetPaymentLink(
                                    data.productPurchase.id
                                  );
                                  window.location = cashNetPaymentLink;
                                }}
                              />
                              {!data.productPurchase.organization && (
                                <div className="text-sm text-gray-500 italic mt-2">
                                  Organization must be set before assigning
                                  seats
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <div>
                        {data.productPurchase.paymentMethod === 'NOT_SET' && (
                          <span className="text-xs text-gray-500 italic ml-2">
                            Payment method must be set before paying
                          </span>
                        )}
                      </div>
                    </div>
                  </section>

                  <section className="mt-8">
                    <h3 className="text-primary font-bold mb-4 text-lg">
                      Seat Assignments
                    </h3>
                    <section className="flex gap-6">
                      <div className="w-1/2">
                        <Formik
                          initialValues={{
                            seatAssignments: data.productPurchase.seatAssignments.map(
                              (sa: ISeatAssignment) => ({
                                seatAssignmentId: sa.id,
                                userId: sa.user?.id || null
                              })
                            )
                          }}
                          onSubmit={async values => {
                            try {
                              if (
                                confirm(
                                  'Seat assignees will receive notifications of their assignments. Are you sure you want to proceed?'
                                )
                              ) {
                                await updateSeatAssignments(
                                  data.productPurchase.id,
                                  values
                                );
                                setIsUpdatingSeats(false);
                                refetch();
                              }
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                        >
                          {({ values, setFieldValue }) => (
                            <Form>
                              <div>
                                {data.productPurchase.seatAssignments.map(
                                  (assignment: ISeatAssignment, i: number) => (
                                    <div key={assignment.id} className="mb-4">
                                      <label className="uppercase text-gray-500 text-sm sans-serif">
                                        Seat Assignee #{i + 1}
                                      </label>
                                      <>
                                        {isUpdatingSeats ? (
                                          <div className="">
                                            <UserSelect
                                              defaultValue={getDefaultAssignedUser(
                                                assignment,
                                                affiliatedUsers
                                              )}
                                              options={getAvailableUsersToAssign(
                                                values.seatAssignments,
                                                affiliatedUsers
                                              )}
                                              onChange={(value: any) => {
                                                const newAssignments = [
                                                  ...values.seatAssignments
                                                ];
                                                newAssignments[i].userId =
                                                  value?.value || null;
                                                setFieldValue(
                                                  'seatAssignments',
                                                  newAssignments
                                                );
                                              }}
                                            />
                                            <button
                                              type="button"
                                              className="text-red-500 text-sm"
                                              onClick={() => {
                                                const newAssignments = [
                                                  ...values.seatAssignments
                                                ];
                                                newAssignments[i].userId = null;
                                                setFieldValue(
                                                  'seatAssignments',
                                                  newAssignments
                                                );
                                              }}
                                            >
                                              Remove
                                            </button>
                                          </div>
                                        ) : (
                                          <div className="border border-gray-200 rounded-md p-2">
                                            {assignment.user ? (
                                              <>
                                                {assignment.user.firstName &&
                                                assignment.user.lastName ? (
                                                  <p className="text-gray-700 sans-serif">
                                                    {assignment.user.firstName}{' '}
                                                    {assignment.user.lastName}
                                                  </p>
                                                ) : (
                                                  <p className="text-gray-500 sans-serif italic">
                                                    Name not set
                                                  </p>
                                                )}
                                                <p className="text-gray-500 sans-serif text-sm">
                                                  {assignment.user.email}
                                                </p>
                                              </>
                                            ) : (
                                              <p className="text-gray-500 sans-serif italic">
                                                Not set
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    </div>
                                  )
                                )}
                              </div>
                              <div>
                                {isUpdatingSeats ? (
                                  <Button
                                    type="submit"
                                    text="Save Seat Assignments"
                                    size="sm"
                                    color="success"
                                  />
                                ) : (
                                  <Button
                                    type="button"
                                    text="Update Seat Assignments"
                                    size="sm"
                                    disabled={
                                      data.productPurchase.paymentStatus !==
                                        'COMPLETED' ||
                                      !data.productPurchase.organization
                                    }
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      setIsUpdatingSeats(true);
                                    }}
                                  />
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      {isUpdatingSeats && (
                        <section className="w-1/2 border border-blue-500 bg-blue-100 rounded-md p-4 h-full mt-6">
                          <p className="text-gray-600 sans-serif">
                            If the staff member you wish to assign to a seat is
                            not in the dropdown list, you can add an account for
                            the staff member first.{' '}
                          </p>
                          <div className="mt-3">
                            <Link to="/members/organization/select">
                              <Button
                                type="button"
                                text="Add New Staff Member"
                                size="sm"
                              />
                            </Link>
                          </div>
                        </section>
                      )}
                    </section>

                    <section>
                      {data.productPurchase.paymentStatus !== 'COMPLETED' && (
                        <div className="text-sm text-gray-500 italic mt-2">
                          Payment must be completed before assigning seats
                        </div>
                      )}
                    </section>
                  </section>
                </div>
              </div>
            </>
          </Card>
        </>
      )}
    </>
  );
};

export default ProductPurchase;
