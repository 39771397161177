import React from 'react';

interface InfoBlockEditProps {
  title: string;
  children: React.ReactNode;
}

const InfoBlockEdit = (props: InfoBlockEditProps) => {
  return (
    <div>
      <label className="uppercase text-gray-500 text-sm sans-serif">
        {props.title}
      </label>
      <div className="mt-1">{props.children}</div>
    </div>
  );
};

export default InfoBlockEdit;
