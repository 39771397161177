export interface ProductPurchaseProps {
  path: string;
  productPurchaseId: string;
}

export const formatPaymentMethod = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'NOT_SET':
      return 'Not Set';
    case 'CREDIT_CARD':
      return 'Credit Card';
    case 'PURCHASE_ORDER':
      return 'Purchase Order';
    default:
      return paymentMethod;
  }
};

export const formatPaymentStatus = (paymentStatus: string) => {
  switch (paymentStatus) {
    case 'PENDING':
      return 'Pending';
    case 'COMPLETED':
      return 'Completed';
    case 'CANCELLED':
      return 'Cancelled';
    case 'REFUNDED':
      return 'Refunded';
    default:
      return paymentStatus;
  }
};

export const paymentMethodOptions = [
  { value: 'CREDIT_CARD', label: 'Credit Card' },
  { value: 'PURCHASE_ORDER', label: 'Purchase Order' }
];
